<template>
  <div class="card lista-perguntas">
    <div class="header">
      <div class="titulo">Catálogo de Perguntas</div>
      <div class="texto-descricao">
        Use-as para se inspirar ou faça adaptações no texto conforme seu objetivo 💡.
      </div>
    </div>
    <div class="body">
      <div
        class="item"
        v-for="(item, index) in listaPerguntas"
        :key="index"
        @click="enviaPergunta(index)"
      >
        <span class="material-symbols-outlined icone-lista"> sms </span>
        <div v-html="item" class="texto-pergunta"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { chatOutboxStore } from "@/stores/chat-Outbox.js";
import { userPreferenceStore } from "@/stores/userPreference";
import { mapWritableState, mapActions } from "pinia";

export default {
  name: "ListaPerguntasSugeridas",

  components: {},

  props: {
    listaPerguntas: Array,
  },

  data() {
    return {};
  },

  watch: {},

  methods: {
    enviaPergunta(index) {
      let perguntaSelecionada = this.listaPerguntas[index];
      perguntaSelecionada = perguntaSelecionada.replace(/<[^>]*>/g, "");

      this.$emit("enviaPerguntaSelecionada", perguntaSelecionada);
    },
  },

  computed: {},

  async mounted() {},
};
</script>

<style lang="scss" scoped>
.lista-perguntas {
  background-color: #fff;
  border-radius: 15px;
  box-shadow: 30px 30px 30px rgba(23, 55, 127, 0.3);
  display: flex;
  flex-direction: column;
  padding: 20px 30px;
  transition: 0.5s;
  height: 555px;
  width: 45%;
  border: none;
  gap: 10px;
  cursor: default;

  &:hover {
    transform: scale(1.05);
  }
}

.header {
  display: flex;
  gap: 10px;
}

.titulo {
  color: #0389dd;
  font-size: 16px;
  font-weight: 500;
  line-height: 18px;
  padding: 0px 10px;
}
.texto-descricao {
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  padding: 0px 10px;
}

.body {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  max-height: 100%;
  gap: 10px;
  flex: 1;
}

.item {
  display: flex;
  gap: 10px;
  background-color: #ececec;
  cursor: pointer;
  align-items: center;
  padding: 5px 10px;
  border-radius: 10px;
  flex: 1;
  max-height: 65px;

  &:hover {
    background-color: #e0e0e0;
  }
}

.icone-lista {
  color: gray;
}

.texto-pergunta {
  font-size: 14px;
  line-height: 14px;
}

.body::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

.body::-webkit-scrollbar-thumb {
  background-color: #aaaaaa;
  border-radius: 10px;
}

.body::-webkit-scrollbar-track {
  background: white;
}
</style>
